import GoogleMapReact from "google-map-react";
import React, { useState, useEffect, useRef } from "react"; // Import useRef
import RouteSummary from "../routehistory/RouteSummary";
import truckIconUrl from "@/assets/location-dot.svg";
import stopSignUrl from "@/assets/stop-sign.svg";
import PlaybackPanel from "./PlaybackPanel";
import FuelChart from "../../../components/fuelchart";

export default function AnimatedTripPlayback({
  locations,
  sampledLocations,
  routeSummary,
  stops,
  fuelLevel
}) {
  // State and defaultProps remain unchanged
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [heatmap, setHeatmap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapType, setMapType] = useState("roadmap");
  const [currentTimestamp, setCurrentTimestamp] = useState("");
  const [isAnimating, setIsAnimating] = useState(true);
  const [progress, setProgress] = useState(0); // Add this line to your existing state declarations
  const [playbackSpeed, setPlaybackSpeed] = useState(1); // 1 is the normal speed

  const animationTimeoutRef = useRef(null); // Ref to store the timeout ID

  const defaultProps = {
    center: { lat: -1.5, lng: 35.5 },
    zoom: 7,
  };

  const [center, setCenter] = useState({
    lat: defaultProps.center.lat,
    lng: defaultProps.center.lng,
  });
  const [zoom, setZoom] = useState(routeSummary?.zoom ?? defaultProps.zoom);

  // Use useRef for the truckMarker and currentIndex of the animation
  const truckMarkerRef = useRef(null);
  const animationIndexRef = useRef(0);

  useEffect(() => {
    if (isAnimating) {
      animateTruck(); // Resume animation when isAnimating changes to true
    }
  }, [isAnimating]); // Add isAnimating to the dependency array

  useEffect(() => {
    // Update center and zoom when routeSummary changes
    if (routeSummary) {
      setCenter({
        lat: routeSummary.center_latitude,
        lng: routeSummary.center_longitude,
      });
      setZoom(routeSummary.zoom);
    }
  }, [routeSummary]);

  useEffect(() => {
    if (map && maps) {
      // Clear existing polyline, heatmap, and markers
      if (polyline) {
        polyline.setMap(null);
      }
      if (heatmap) {
        heatmap.setMap(null);
      }
      markers.forEach((marker) => marker.setMap(null));
      setMarkers([]);

      // Initialize or move truck marker
      if (locations.length > 0) {
        if (truckMarkerRef.current) {
          truckMarkerRef.current.setMap(null); // Clear existing marker using ref
        }
        const truckIcon = {
          url: truckIconUrl,
          scaledSize: new maps.Size(20, 20),
          anchor: new maps.Point(16, 16),
        };
        const initialLocation = locations[0];
        truckMarkerRef.current = new maps.Marker({
          position: {
            lat: initialLocation.latitude,
            lng: initialLocation.longitude,
          },
          map: map,
          icon: truckIcon,
        });

        // Create new Polyline for the route history
        const routePath = locations.map((location) => ({
          lat: location.latitude,
          lng: location.longitude,
        }));
        const newPolyline = new maps.Polyline({
          path: routePath,
          strokeColor: "#fbbe24",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        newPolyline.setMap(map);
        setPolyline(newPolyline); // Update state with new polyline

        animateTruck(); // Start the animation from the first location
      }
    }
  }, [map, maps, locations]); // Make sure to include locations in dependency array to update polyline when locations change

  useEffect(() => {
    const stopMarkers = []; // Temporary array to hold stop markers for cleanup

    if (map && maps && stops && stops.length > 0) {
      // Iterate over stops data to create markers and info windows
      stops.forEach((stop) => {
        const stopPosition = new maps.LatLng(stop.latitude, stop.longitude);
        const stopMarker = new maps.Marker({
          position: stopPosition,
          map: map,
          title: "Stop",
          icon: {
            url: stopSignUrl, // Specify the path to your stop sign icon
            scaledSize: new maps.Size(30, 30), // Adjust size as needed
            anchor: new maps.Point(15, 15), // Adjust anchor as needed
          },
        });

        // Content for the stop info window
        const stopInfo = new maps.InfoWindow({
          content: `<div><strong>Stop Duration:</strong> ${stop.duration.toFixed(
            2
          )} hours<br><strong>Start:</strong> ${
            stop.start_time
          }<br><strong>End:</strong> ${stop.end_time}</div>`,
        });

        // Add click listener to show stop details
        stopMarker.addListener("click", () => {
          stopInfo.open(map, stopMarker);
        });

        // Add the created marker to the temporary array
        stopMarkers.push(stopMarker);
      });
    }

    // Cleanup function to remove markers when the component unmounts or when stops data changes
    return () => {
      stopMarkers.forEach((marker) => marker.setMap(null));
    };
  }, [stops]); // Depend on stops to rerender markers when stops data changes

  // Adjust animateTruck to use refs for the truckMarker and control of currentIndex
  const animateTruck = () => {
    clearTimeout(animationTimeoutRef.current); // Clear any existing animation timeout
    if (
      !truckMarkerRef.current ||
      animationIndexRef.current >= locations.length ||
      !isAnimating
    ) {
      return; // Exit if there's no marker, we're at the end, or not animating
    }

    // Proceed to set the truck's position and schedule the next animation frame
    const nextLocation = locations[animationIndexRef.current];
    truckMarkerRef.current.setPosition(
      new maps.LatLng(nextLocation.latitude, nextLocation.longitude)
    );
    setCurrentTimestamp(nextLocation.time);
    setCenter({ lat: nextLocation.latitude, lng: nextLocation.longitude });

    if (isAnimating) {
      animationTimeoutRef.current = setTimeout(() => {
        animationIndexRef.current += 1;
        animateTruck();
      }, 100 / playbackSpeed); // Use the current playbackSpeed to adjust timing
    }
  };

  // Effect hook to restart animation when playbackSpeed changes
  useEffect(() => {
    if (isAnimating) {
      animateTruck(); // Restart animation with new speed, if it was already running
    }
    // This effect should only re-run when playbackSpeed changes AND the animation is ongoing
  }, [playbackSpeed]); // Add playbackSpeed as a dependency

  // Function to toggle animation state
  const toggleAnimation = () => {
    setIsAnimating(!isAnimating);
    if (isAnimating) {
      clearTimeout(animationTimeoutRef.current); // Clear the current animation step if pausing
    } else {
      animateTruck(); // Resume animation
    }
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    map.addListener("tilesloaded", function () {
      setMap(map);
      setMaps(maps);
    });
  };

  // Update progress calculation inside animateTruck or wherever you update animationIndexRef.current
  useEffect(() => {
    if (locations.length > 0) {
      const progressPercentage =
        (animationIndexRef.current / (locations.length - 1)) * 100;
      setProgress(Math.floor(progressPercentage));
    }
  }, [animationIndexRef.current, locations.length]); // Add this effect to update progress as the animation index changes

  const jumpSize = 6; // Define how many steps to jump. Adjust according to your needs

  const moveTruckToIndex = (index) => {
    // Move the truck marker to the specified index location
    const location = locations[index];
    truckMarkerRef.current.setPosition(
      new maps.LatLng(location.latitude, location.longitude)
    );
    setCurrentTimestamp(location.time);
    setCenter({ lat: location.latitude, lng: location.longitude });
    // Update progress to reflect the new position
    const progressPercentage = (index / (locations.length - 1)) * 100;
    setProgress(Math.floor(progressPercentage));
  };

  const onJumpBack = () => {
    const newIndex = Math.max(0, animationIndexRef.current - jumpSize);
    animationIndexRef.current = newIndex;
    moveTruckToIndex(newIndex);
    // Only continue animation if it was previously running
    if (isAnimating) animateTruck();
  };

  const onJumpForward = () => {
    const newIndex = Math.min(
      locations.length - 1,
      animationIndexRef.current + jumpSize
    );
    animationIndexRef.current = newIndex;
    moveTruckToIndex(newIndex);
    // Only continue animation if it was previously running
    if (isAnimating) animateTruck();
  };

  console.log(progress);

  return (
    <div className="w-full h-full pl-4 relative">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU",
          libraries: "visualization",
        }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{
          mapTypeId: mapType,
          mapId: "79ba85b61acfbcf1",
          disableDefaultUI: true,
        }}
      />
      {routeSummary && <RouteSummary data={routeSummary} />}
      {routeSummary && <FuelChart fuelLevel={fuelLevel} />}
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 30,
          zIndex: 5,
        }}
      >
        <select
          value={mapType}
          onChange={(e) => setMapType(e.target.value)}
          style={{
            padding: "10px", // Add more padding inside the select
            border: "2px solid #bbbbbb", // A solid border with a custom color
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
            borderRadius: "5px", // Optional: if you want rounded corners
            backgroundColor: "white", // Ensures the background is white
            cursor: "pointer", // Changes the cursor to indicate it's clickable
          }}
        >
          <option value="roadmap">Default</option>
          <option value="satellite">Satellite</option>
          <option value="hybrid">Hybrid</option>
          <option value="terrain">Terrain</option>
        </select>
      </div>

      {routeSummary && (
        <PlaybackPanel
          currentTimestamp={currentTimestamp}
          isAnimating={isAnimating}
          toggleAnimation={toggleAnimation}
          progress={progress} // New prop for the progress bar
          onJumpBack={onJumpBack} // New prop for jumping back
          onJumpForward={onJumpForward} // New prop for jumping forward
          playbackSpeed={playbackSpeed}
          setPlaybackSpeed={setPlaybackSpeed}
        />
      )}
    </div>
  );
}

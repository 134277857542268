import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"

export default function FuelChart({ fuelLevel }) {
  const chartConfig = {
    fuel_level: {
      label: "Fuel Level",
      color: "rgb(30 58 138)", // Choose your preferred color
    },
  }

  return (
    <div className="bg-slate-50 shadow-lg w-1/3 rounded-lg p-6 flex-col space-y-4 absolute top-[2%] right-[1%]">
      <div className="text-sm font-semibold uppercase">Fuel Level In Litres</div>
      <ChartContainer config={chartConfig}>
        <AreaChart
          data={fuelLevel}
          margin={{ left: -30, right: -20, bottom: -10 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="timestamp"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            padding={{ left: 0, right: 0 }}
            tick={false} // Remove labels from x-axis
            scale="point" // Remove extra padding
          />
          <YAxis />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <defs>
            <linearGradient id="fillFuelLevel" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={chartConfig.fuel_level.color}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={chartConfig.fuel_level.color}
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <Area
            dataKey="fuel_level"
            type="natural"
            fill="url(#fillFuelLevel)"
            fillOpacity={0.5}
            stroke={chartConfig.fuel_level.color}
            strokeWidth={2} // Increased line width
          />
        </AreaChart>
      </ChartContainer>
    </div>
  )
}

import { Button } from "@/components/ui/button";
import React from "react";

export default function PlaybackPanel({
  currentTimestamp,
  isAnimating,
  toggleAnimation,
  progress, // New prop: represents the current progress as a percentage
  onJumpBack, // New prop: function to handle jumping back
  onJumpForward, // New prop: function to handle jumping forward
  playbackSpeed,
  setPlaybackSpeed,
}) {
  // Convert the incoming timestamp string into a Date object
  const dateObj = new Date(currentTimestamp);
  dateObj.setTime(dateObj.getTime() + 3 * 60 * 60 * 1000); // Add 3 hours

  // Define day names and months
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Format the date and day
  // Assuming dateObj is your Date object
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const dayOfWeek = days[dateObj.getDay()];

  // Format time without seconds
  const time = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Determine if it's daytime or nighttime (considering daytime from 6AM to 6PM)
  const hour = dateObj.getHours();
  const isDaytime = hour >= 6 && hour < 18;

  // Styling based on time of day
  const dateStyle = isDaytime
    ? { backgroundColor: "rgb(255 251 235)", color: "rgb(120 53 15)" } // Light yellow for daytime
    : { backgroundColor: "rgb(238 242 255)", color: "rgb(49 46 129)" }; // Light grey for nighttime

  const icon = isDaytime ? "☀️" : "🌙"; // Sun for day, Moon for night

  if (currentTimestamp) {
    return (
      <div
        className="bg-slate-50 shadow-lg w-1/2 rounded-lg p-2 flex absolute right-20 transform -translate-x-2/3 bottom-[2%]"
        style={{ ...dateStyle }}
      >
        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "66%", // Take half of the container width
            minHeight: 200,
          }}
        >
          <div className="text-4xl" style={{ alignSelf: "start" }}>
            {icon}
          </div>
          <div className="text-5xl font-semi">{time}</div>
          <div className="text-xl mt-4">{formattedDate}</div>
          <div className="text-xl font-light">{dayOfWeek.toUpperCase()}</div>
        </div>
        {/* Placeholder for playback controls */}
        <div className="flex-1 flex-col mr-4 mt-4">
          <div className="w-full h-2 mt-10 bg-gray-400 rounded">
            <div
              style={{ width: `${progress}%`, height: "100%" }}
              className="bg-blue-500 rounded"
            ></div>
          </div>
          <div className="flex w-full justify-center mt-4 space-x-2">
            <div className="w-1/8">
              <Button onClick={onJumpBack}>{"<<"}</Button>
            </div>
            <div>
              <Button onClick={toggleAnimation}>
                {isAnimating ? "Pause" : "Play"}
              </Button>
            </div>
            <div className="w-1/8">
              <Button onClick={onJumpForward}>{">>"}</Button>
            </div>
          </div>

          {/* New playback speed dropdown */}
          <div className="flex w-full justify-center mt-2">
            <select
              value={playbackSpeed}
              onChange={(e) => setPlaybackSpeed(Number(e.target.value))}
              style={{
                padding: "10px",
                border: "2px solid #bbbbbb",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <option value={0.25}>0.25x</option>
              <option value={1}>1x</option>
              <option value={2}>2x</option>
              <option value={5}>5x</option>
              <option value={10}>10x</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

import HeadlineNumbers from "@/components/headline";
import RevenueIcon from "@/components/icons/RevenueIcon";
import formatDateToString from "@/utils/FormatDateToString";
import CalculatorIcon from "@/components/icons/CalculatorIcon";
import formatNumberWithLetters from "@/utils/formatNumberWithLetters";

import useNumberOfVehiclesFitted from "@/hooks/useNumberOfVehiclesFitted";
import useTotalSparePartsCount from "@/hooks/useTotalSparePartsCount";
import useTotalSparePartsQuantity from "@/hooks/useTotalSparePartsQuantity";
import useAverageSparePartsExpense from "@/hooks/useAverageSparePartsExpense";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

export default function PartsHeadline({
  startDate,
  endDate,
  currency,
  totalSparePartsExpense,
  loadingTotalSparePartsExpense,
}) {
  const { data: totalSparePartsCount, loading: loadingTotalSparePartsCount } =
    useTotalSparePartsCount(startDate, endDate);

  const {
    data: numberOfVehiclesFitted,
    loading: loadingNumberOfVehiclesFitted,
  } = useNumberOfVehiclesFitted(startDate, endDate);

  const {
    data: totalSparePartsQuantity,
    loading: loadingTotalSparePartsQuantity,
  } = useTotalSparePartsQuantity(startDate, endDate);

  const {
    data: averageSparePartsExpense,
    loading: loadingAverageSparePartsExpense,
  } = useAverageSparePartsExpense(startDate, endDate, currency);

  const PartsCards = [
    {
      name: "Total Parts Expense",
      icon: <RevenueIcon />,
      value: totalSparePartsExpense
        ? `${formatNumberWithLetters(
            totalSparePartsExpense.total_spare_parts_expense
          )} ${totalSparePartsExpense.total_spare_parts_expense_currency}`
        : "",
      percentage: totalSparePartsExpense
        ? totalSparePartsExpense.percent_change
        : "",
      desc: `Total spare parts expense incurred from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: totalSparePartsExpense
        ? `This amount is ${
            totalSparePartsExpense.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            totalSparePartsExpense.percent_change
          )}% from the previous period: ${formatDateToString(
            totalSparePartsExpense.prev_start_date
          )} to ${formatDateToString(totalSparePartsExpense.prev_end_date)}.`
        : "",
      loading: loadingTotalSparePartsExpense,
    },
    {
      name: "Number of Receipts",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: totalSparePartsCount
        ? `${formatNumberWithLetters(
            totalSparePartsCount.total_spare_parts_fitted
          )}`
        : "",
      percentage: totalSparePartsCount
        ? totalSparePartsCount.percent_change
        : "",
      desc: `Total number of spare parts fitted from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: totalSparePartsCount
        ? `This amount is ${
            totalSparePartsCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            totalSparePartsCount.percent_change
          )}% from the previous period: ${formatDateToString(
            totalSparePartsCount.prev_start_date
          )} to ${formatDateToString(totalSparePartsCount.prev_end_date)}.`
        : "",
      loading: loadingTotalSparePartsCount,
    },
    {
      name: "Number of Vehicles",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: numberOfVehiclesFitted
        ? `${formatNumberWithLetters(
            numberOfVehiclesFitted.number_of_vehicles_fitted
          )}`
        : "",
      percentage: numberOfVehiclesFitted
        ? numberOfVehiclesFitted.percent_change
        : "",
      desc: `Total number of vehicles fitted with spare parts from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: numberOfVehiclesFitted
        ? `This amount is ${
            numberOfVehiclesFitted.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            numberOfVehiclesFitted.percent_change
          )}% from the previous period: ${formatDateToString(
            numberOfVehiclesFitted.prev_start_date
          )} to ${formatDateToString(numberOfVehiclesFitted.prev_end_date)}.`
        : "",
      loading: loadingNumberOfVehiclesFitted,
    },
    {
      name: "Total Quantity of Parts",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: totalSparePartsQuantity
        ? `${formatNumberWithLetters(
            totalSparePartsQuantity.total_quantity_of_parts_fitted
          )}`
        : "",
      percentage: totalSparePartsQuantity
        ? totalSparePartsQuantity.percent_change
        : "",
      desc: `Total quantity of spare parts fitted from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: totalSparePartsQuantity
        ? `This amount is ${
            totalSparePartsQuantity.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            totalSparePartsQuantity.percent_change
          )}% from the previous period: ${formatDateToString(
            totalSparePartsQuantity.prev_start_date
          )} to ${formatDateToString(totalSparePartsQuantity.prev_end_date)}.`
        : "",
      loading: loadingTotalSparePartsQuantity,
    },
    {
      name: "Average Price Per Part",
      icon: <RevenueIcon />,
      value: averageSparePartsExpense
        ? `${formatNumberWithCommas(
            averageSparePartsExpense.average_spare_part_expense
          )} ${averageSparePartsExpense.average_spare_part_expense_currency}`
        : "",
      percentage: averageSparePartsExpense
        ? averageSparePartsExpense.percent_change
        : "",
      desc: `Total average expense per spare part incurred from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: averageSparePartsExpense
        ? `This amount is ${
            averageSparePartsExpense.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            averageSparePartsExpense.percent_change
          )}% from the previous period: ${formatDateToString(
            averageSparePartsExpense.prev_start_date
          )} to ${formatDateToString(averageSparePartsExpense.prev_end_date)}.`
        : "",
      loading: loadingAverageSparePartsExpense,
    },
  ];

  return <HeadlineNumbers data={PartsCards} />;
}
